import { render, staticRenderFns } from "./BuyerPictureView.vue?vue&type=template&id=1b8c07e6&scoped=true"
import script from "./BuyerPictureView.vue?vue&type=script&lang=js"
export * from "./BuyerPictureView.vue?vue&type=script&lang=js"
import style0 from "./BuyerPictureView.vue?vue&type=style&index=0&id=1b8c07e6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b8c07e6",
  null
  
)

export default component.exports