<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card class="card-plain">
          <!-- <template slot="header">
            <h4 class="card-title">Table on Plain Background</h4>
            <p class="category">Here is a subtitle for this table</p>
          </template>-->
          <div class="table-full-width text-left">
            <watchList-table
            v-if="watchLists && watchLists.length > 0"
				:title="table1.title"
				:sub-title="table1.subTitle"
				:watchLists="watchLists"
				:totalElements="totalElements"
				:page="page"
				:columns="table1.columns"
 				@get-all-watch-lists="getAllWatchLists"
             >

            </watchList-table>
          </div>
        </card>
      </div>

    </div>
  </div>
</template>
<script>
import { Card } from "@/components/Card";

import WatchListTable from "@/components/WatchListTable";
import WatchListService from "../services/WatchListService";

const tableColumns = [];
const tableData = [
];

export default {
  components: {
    Card,
    WatchListTable,
  },
  data() {
    return {
      watchLists: [],
	  totalElements: 0,
      page: 0,
      searchQuery: '',     
      table1: {
        title: "Simple Table",
        columns: [...tableColumns],
        data: [...tableData],
      },
    };
  },
  methods: {
    async getAllWatchLists(sortBy='watchListId',sortOrder='true',page="0",size="50") {
      try {
        try {
			const searchDTO = {
				sortBy: sortBy, 
				sortOrder: sortOrder, 
				searchQuery: this.searchQuery,
				page: page, 
				size: size
			};
	        let response = await WatchListService.getAllWatchLists(searchDTO);
	        if (!response.data.empty) {
		        if (response.data.watchLists.length) {
					this.watchLists = response.data.watchLists;
				}
      			this.totalElements = response.data.totalElements;
      			this.page = response.data.page;
	        }
        } catch (error) {
          console.error("Error fetching watchLists:", error);
        }
        
      } catch (error) {
        console.error("Error fetching watchList details:", error);
      }
    },
  },
  mounted() {
    this.getAllWatchLists();
  },
  created() {
    this.$root.$on('searchQueryForWatchListsChanged', (searchQuery) => {
    	this.searchQuery = searchQuery;
    	this.getAllWatchLists();
    })
  }
};
</script>
<style></style>
